import React, { useState } from "react";
import "../Headers/Sidebar/Sidebar.scss";
import Home_icon from "../../Assets/Images/home-icon.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

function MasterItems() {
  const [menuOpenMaster, setMenuOpenMaster] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);
  const [descriptionTypeOpen, setDescriptionTypeOpen] = useState(false);
  const [descriptionNameOpen, setDescriptionNameOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterTypes, setFilterTypes] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  return (
    <div className="text-decoration-none">
      <li
        onClick={() => setMenuOpenMaster(!menuOpenMaster)}
        aria-controls="example-collapse-text"
        aria-expanded={menuOpenMaster}
      >
        <div className="d-flex">
          <img className="sidebar-icons" src={Home_icon} />
          <div className="sidebar-text">Master</div>
        </div>
        {menuOpenMaster === false ? (
          <IoIosArrowForward className="sidebar-menu-icon" />
        ) : (
          <IoIosArrowDown className="sidebar-menu-icon" />
        )}
      </li>

      <Collapse in={menuOpenMaster}>
        <div id="example-collapse-text" className="mx-2">
          <li
            onClick={() => setCategoryOpen(!categoryOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={categoryOpen}
            style={{ fontSize: "13px" }}
          >
            <div className="sidebar-text">Category</div>
            {categoryOpen === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={categoryOpen}>
            <div className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/productcategory"
                >
                  Create Category
                </Link>
              </li>

              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/category"
                >
                  Edit Category
                </Link>
              </li>
            </div>
          </Collapse>

          <li
            onClick={() => setSubCategoryOpen(!subCategoryOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={subCategoryOpen}
            style={{ fontSize: "13px" }}
          >
            <div className="sidebar-text">Sub Category</div>
            {subCategoryOpen === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={subCategoryOpen}>
            <div className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/subproductcategory"
                >
                  Create Sub Category
                </Link>
              </li>

              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/subCategory"
                >
                  Edit Sub Category
                </Link>
              </li>
            </div>
          </Collapse>

          {/* <Link
            className={"text-dark text-decoration-none"}
            style={{ fontSize: "13px" }}
            to="/createDescriptionMethod"
          >
            <li className="mx-2">Description Method</li>
          </Link> */}

          <li
            onClick={() => setDescriptionNameOpen(!descriptionNameOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={descriptionTypeOpen}
            style={{ fontSize: "13px" }}
          >
            <div className="sidebar-text">Description Name</div>
            {descriptionNameOpen === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={descriptionNameOpen}>
            <div className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/createDescriptionType"
                >
                  Create Description Name
                </Link>
              </li>

              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/descriptionType"
                >
                  Edit Description Name
                </Link>
              </li>
            </div>
          </Collapse>

          {/* <li
            onClick={() => setDescriptionTypeOpen(!descriptionTypeOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={descriptionTypeOpen}
            style={{ fontSize: "13px" }}
          >
            <div className="sidebar-text">Description Name Types</div>
            {descriptionTypeOpen === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={descriptionTypeOpen}>
            <div className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/createDescriptionNameTypes"
                >
                  Create Description Name Types
                </Link>
              </li>

              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/viewDescriptionNameTypes"
                >
                  Edit Description Name Types
                </Link>
              </li>
            </div>
          </Collapse> */}

          <li
            onClick={() => setFilter(!filter)}
            aria-controls="example-collapse-text"
            aria-expanded={filter}
          >
            <div className="sidebar-text">Filters</div>
            {filter === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={filter}>
            <div id="example-collapse-text" className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/filters"
                >
                  Create Filters
                </Link>
              </li>
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/filtersDetials"
                >
                  Edit Filters
                </Link>
              </li>
            </div>
          </Collapse>

          <li
            onClick={() => setFilterTypes(!filterTypes)}
            aria-controls="example-collapse-text"
            aria-expanded={filter}
          >
            <div className="sidebar-text">Filters Types</div>
            {filterTypes === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={filterTypes}>
            <div id="example-collapse-text" className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/filtersTypes"
                >
                  Create Filters Types
                </Link>
              </li>
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/editFiltersTypes"
                >
                  Edit Filters Types
                </Link>
              </li>
            </div>
          </Collapse>

          {/* <li
            onClick={() => setDescriptionOpen(!descriptionOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={descriptionOpen}
            style={{ fontSize: "13px" }}
          >
            <div className="sidebar-text">Description Title</div> 
            {descriptionOpen === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={descriptionOpen}>
            <div className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/underSubCategory"
                >
                  Create Description Title
                </Link>
              </li>

              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/underSubProductCategory"
                >
                  Edit Description Title
                </Link>
              </li>
            </div>
          </Collapse> */}
        </div>
      </Collapse>
    </div>
  );
}

export default MasterItems;
