import React, { useContext, useEffect } from "react";
import "../Navbar/Navbar.scss";
import "../Sidebar/Sidebar.scss";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Sidebar/responsiveSidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { API_BASE_URL } from "../../../utils/index";
import ProductsItems from "../../SidebarItems/ProductsItems";
import userContext from "../../useContext/userContext";
import MasterItems from "../../SidebarItems/MasterItems";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [menuOpen, setmenuOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const handleCloseData = useContext(userContext);
  const handleClick = handleCloseData.handleClose;

  useEffect(() => {
    const adminId = localStorage.getItem("adminId");

    if (!adminId) {
      navigate("/login");
      return;
    }

    const headers = {
      Authorization: `Bearer ${adminId}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/admin/profile`, {
        // withCredentials: true,
        headers,
      })
      .then((result) => {
        // console.log("result loki:", result.data);
        setProduct(result.data.admin);
        // Handle the profile data, update state, etc.
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made, but the server responded with an error
          console.log("response data", error.response.data);
          console.log("response status", error.response.status);
          console.log("response headers", error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("request made but no response received", error.request);
        } else {
          // Something happened in setting up the request that triggered an error
          console.log("error setting up the request", error.message);
        }
        console.error("Error fetching profile:", error.config);
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("adminId");
    window.location.reload(false);
  };

  const [admin, setAdmin] = useState();

  useEffect(() => {
    const adminId = localStorage.getItem("adminId");

    const headers = {
      Authorization: `Bearer ${adminId}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/admin/profile`, {
        headers,
      })
      .then((result) => {
        setAdmin(result.data.admin);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="dashboard-nav px-4">
      <div className="nav-menu">
        <svg
          onClick={handleClick}
          className="fs-5"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
      </div>

      <div className="responsive-sidebar">
        <svg
          onClick={handleShow}
          className="fs-5"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>

        <Offcanvas show={show} onHide={handleClose} backdrop="static">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="text-dark"></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="text-danger">
            <h3 className="text-dark">CHENNAI FURNITURE</h3>

            {/* <div className="py-4">
              <Link to="/" className="text-decoration-none">
                <div
                  className="responsive-sidebar-iteam d-flex justify-content-between"
                  onClick={() => setmenuOpen(!menuOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={menuOpen}
                >
                  <div className="d-flex align-items-center">
                    <img className="responsive-sidebar-icon" src={Home_icon} />
                    <p className="m-2 text-dark ">DashBoard</p>
                  </div>

                  <img
                    src={sidebar_menu_icon}
                    className=" sidebarmenu-icon"
                    alt="sidebar_menu_icon"
                  />
                </div>
                <Collapse in={menuOpen}>
                  <div id="example-collapse-text">
                    <li>
                      <div className=" text-dark">DashBoard</div>
                    </li>
                    <li>
                      <div className="text-dark ">Users</div>
                    </li>
                    <li>
                      <div className="text-dark ">Roules</div>
                    </li>
                  </div>
                </Collapse>
              </Link>
            </div> */}
            <div className="sidebar-items">
              {/* <DashBoardIteams /> */}
              <ProductsItems  />
              {/* <CouponItems /> */}
              {/* <ShippingItems /> */}
              <MasterItems />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div className="d-flex align-iteam-center">
        <Link to="/profilepage" className="user">
          {/* <img className="user-image mx-2" src={userImage} /> */}
        </Link>

        <Dropdown className="user">
          <Dropdown.Toggle id="dropdown-button-dark-example1">
            <div className="user-name">{admin ? admin.name : "Loading..."}</div>
            <CgProfile className="mx-2 text-dark fs-4" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/profilepage">Profile Page</Dropdown.Item>
            <Dropdown.Divider />
            <div className="w-100 d-flex align-items-center justify-content-center">
              <button
                className="py-1 px-2 rounded  logout"
                onClick={handleLogout}
              >
                Log Out
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
