import React, { useContext, useEffect, useState } from "react";
import "../ProfilePage/profilePage.scss";
import userImage from "../../Assets/Images/user-image2.png";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminName from "../../components/admin/AdminName";
import { API_BASE_URL } from "../../utils/index";
import userContext from "../../components/useContext/userContext";

export default function ProfilePage() {
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  const adminDetial = useContext(userContext);

  useEffect(() => {
    const adminId = localStorage.getItem("adminId");

    if (!adminId) {
      navigate("/login");
      return;
    }

    const headers = {
      Authorization: `Bearer ${adminId}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/admin/profile`, {
        headers,
      })
      .then((result) => {
        setProduct(result.data.admin);

        adminDetial.setAdmin(result.data.admin);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
        }
      });
  }, [adminDetial.profile]);

  return (
    <div className="profile-container">
      <div className="profile px-4">
        <div className="d-flex flex-wrap">
          <div className="avatar">
            <CgProfile className="userAvatar" />
          </div>
          <div className="admin-details m-2">
            <h3 className="m-0">
              <AdminName product={product} />
            </h3>
            <p className="m-0">{product ? product.email : "Loading..."}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
