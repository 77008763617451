import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";

function ViewFiltersTypes() {
  const { _id } = useParams();

  const [data, setdata] = useState({});
  const [getFilter, setGetFilter] = useState({});

  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefiltertype/${_id}`, {
        headers,
      })
      .then((response) => {
        setdata(response.data.filtertype);
        // setGetFilter(response.data.filtertype.filter);
        console.log("response data :12:", response.data.filtertype);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Filter Details</h2>
      <h6>Filter:</h6>
      <p>{data?.filter?.name}</p>

      <h6>Filters Types:</h6>
      {data?.filter_Types?.map((item) => (
        <p className="m-0"> {item} </p>
      ))}
    </div>
  );
}

export default ViewFiltersTypes;
