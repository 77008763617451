import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../utils/index"

function CategorysDetial() {
  const { _id } = useParams();

  const [category, setCategory] = useState({});

  console.log(category, "category");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getsinglecategory/${_id}`)
      .then((response) => {
        setCategory(response.data.category); 
        console.log("response ::", response.data.category)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Category Details</h2>
      <h6>Name:</h6>
      <p>{category.name}</p>
      <h6>createdAt</h6>
      <p>{category.createdAt}</p>
    </div>
  );
}

export default CategorysDetial;
