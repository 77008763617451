import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";

function NewProductsDetials() {
  const { _id } = useParams();
  const [productDetial, setProductDetial] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/products/singleproduct/${_id}`)
      .then((response) => {
        setProductDetial(response.data.product);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const description = productDetial?.description;

  const productImages = productDetial?.productimages;

  return (
    <div className="p-4">
      <h2>Product Detial </h2>

      <div className="d-flex py-2">
        <h6>Product Name:</h6>
        <p className="px-2 m-0">{productDetial.productName || "Loading..."} </p>
      </div>

      <div className="d-flex my-4">
        {productImages?.map((image, i) => (
          <img
            src={image?.productimage}
            width={80}
            height={80}
            alt="detials-image"
            key={i}
            style={{ objectFit: "cover" }}
          />
        ))}
      </div>
      <div className="d-flex  py-2">
        <h6>Category Name :</h6>
        <p className="px-2 m-0">{productDetial.categoryname || "Loading..."}</p>
      </div>

      <div className="d-flex  py-2">
        <h6>Sub Category Name :</h6>
        <p className="px-2 m-0">
          {productDetial.subcategoryname || "Loading..."}
        </p>
      </div>

      {description &&
        description.map((item, i) => (
          <div key={i}>
            <h6>{item?.name || "Loading..."} :</h6>
            <p>{item?.value || "Loading..."}</p>
          </div>
        ))}

      <div className="d-flex  py-2">
        <h6>Original Price :</h6>
        <p className="px-2 m-0">
          {productDetial.originalprice || "Loading..."}
        </p>
      </div>

      <div className="d-flex  py-2">
        <h6>Sale Price :</h6>
        <p className="px-2 m-0">{productDetial.saleprice || "Loading..."}</p>
      </div>

      <div className="d-flex  py-2">
        <h6>Manufacturer :</h6>
        <p className="px-2 m-0">{productDetial.manufacturer || "Loading..."}</p>
      </div>

      <div className="d-flex  py-2">
        <h6>Stock :</h6>
        <p className="px-2 m-0">{productDetial.stock || "Loading..."}</p>
      </div>

      <h6>Product Color</h6>
      {productDetial?.Productcolor?.map((item, i) => (
        <p key={i}>{item || "Loading..."}</p>
      ))}
    </div>
  );
}

export default NewProductsDetials;
