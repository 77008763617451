import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../utils";
import userContext from "./useContext/userContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authHeaders, setAuthHeaders] = useState(() => {
    const adminId = localStorage.getItem("adminId");

    return {
      Authorization: `Bearer ${adminId}`,
    };
  });

  const ProfileContext = useContext(userContext);

  // api
  const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem("refreshToken");

    try {
      const response = await axios.post(`${API_BASE_URL}/admin/refreshToken`, {
        refreshToken: storedRefreshToken,
      });

      const newAccessToken = response.data;

      console.log("response in refreshToken api", response.data);

      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        const newAccessToken = await refreshToken();

        const adminId = newAccessToken.token;
        const newRefreshToken = newAccessToken.newRefreshToken;

        localStorage.setItem("adminId", adminId);
        localStorage.setItem("refreshToken", newRefreshToken);

        ProfileContext.setProfile(true);

        setAuthHeaders({
          Authorization: `Bearer ${newAccessToken.token}`,
        });
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    // const checkTokenInterval = setInterval(refreshAccessToken, 120000);

    const checkTokenInterval = setInterval(refreshAccessToken, 3600000); // 1 hour interval

    // refreshAccessToken();

    return () => clearInterval(checkTokenInterval);
  }, []);

  return (
    <AuthContext.Provider value={authHeaders}>{children}</AuthContext.Provider>
  );
};

export const useAuthHeaders = () => {
  return useContext(AuthContext);
};