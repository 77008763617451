import React, { useEffect, useState } from "react";
import "../ProductCategory/productCategory.scss";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-tagsinput/dist/index.css";
import SelectDescriptionType from "../../../components/SelectDescriptionType/SelectDescriptionType";
import DescriptionCategory from "../../../components/DescriptionTitle/DescriptionCategory ";
import DescriptionSubCategory from "../../../components/DescriptionTitle/DescriptionSubCategory";
import DescriptionName from "../../../components/DescriptionTitle/DescriptionName";
import DescriptionTypesField from "../../../components/DescriptionNameType/DescriptionTypesField";
import DescriptionTypes from "../../../components/DescriptionTitle/DescriptionTypes";
import {API_BASE_URL} from "../../../utils/index"


function UnderSubCategory() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [discriptionName, setDiscriptionName] = useState([]);
  const [selectedDiscriptionName, setSelectedDiscriptionName] = useState("");
  const [discriptionType, setDiscriptionType] = useState([]);
  const [selectedDescriptionType, setSelectedDescriptionType] = useState("");
  const [discriptionMethod, setDiscriptionMethod] = useState([]);
  const [selectedDescriptionMethod, setSelectedDescriptionMethod] =
    useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();

  // State for an array of input fields
  const defaultData = {
    name: "",
    descriptionType: "",
    type: "",
  };

  const [inputFields, setInputFields] = useState([{ ...defaultData }]);

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const data = [...inputFields];
    data[index][name] = value;
    setInputFields(data);
  };

  const onAddClick = () => {
    setInputFields([...inputFields, { ...defaultData }]);
  };

  const onRemoveClick = (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  //

  const sampleTost = (data) => {
    console.log("came inside sampleTost");
    return (
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Body>{data}</Toast.Body>
      </Toast>
    );
  };

  useEffect(() => {
    sampleTost("sample testing");
    // Fetch categories
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
        // console.log(response.data.categories," catrgories")
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
    // Fetch Description method data
    axios
      .get(`${API_BASE_URL}/formfieldcategory/admin/getformfieldcategories`)
      .then((response) => {
        setDiscriptionMethod(response.data.formfieldcategory);
        // console.log("response :",response.data.formfieldcategory)
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const descriptionMethodData = {
      name: selectedDiscriptionName,
      nametypes: selectedDescriptionType,
      type: selectedDescriptionMethod,
    };

    axios
      .post(`${API_BASE_URL}/admin/descriptionmethod`, {
        categoryname: selectedCategory,
        subcategoryname: selectedSubCategory,
        descriptionmethod: [descriptionMethodData],
      })
      .then((response) => {
        setIsSuccess(true);
        // navigate("/underSubProductCategory");
        // Clear the form and reset the state
        setSubCategory("");
        setSelectedCategory("");
        setDiscriptionMethod("");
        setDiscriptionType("");
      })
      .catch((error) => {
        console.log("message AxiosError ", error?.AxiosError?.message);
        console.log("message ", error?.response?.data?.message);
        setShow(true);
        if (error?.response?.data?.message) {
          setErrorMsg(error?.response?.data?.message);
          sampleTost(error?.response?.data?.message);
        }
        // console.error("Error adding Description Method:", error);
        setIsSuccess(false);
      });
  };

  return (
    <div className="p-4">
      <h2>Description</h2>
      <Toast bg="danger" delay={500} show={show} autohide={true}>
        <Toast.Body className="text-white">{errorMsg}</Toast.Body>
      </Toast>
      <form className="category" onSubmit={handleFormSubmit}>
        <div className="row category-field">
          <DescriptionCategory
            categories={categories}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            setSubCategory={setSubCategory}
          />

          <DescriptionSubCategory
            subCategory={subCategory}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            setDiscriptionName={setDiscriptionName}
          />

          <div className="border py-2 position-relative">
            <h6>Description Method</h6>

            {inputFields.map((field, index) => (
              <div key={field.id} className="my-2 row">
                <DescriptionName
                  discriptionName={discriptionName}
                  setSelectedDiscriptionName={setSelectedDiscriptionName}
                  selectedDiscriptionName={selectedDiscriptionName}
                  setDiscriptionType={setDiscriptionType}
                  onChange={(e) => onChange(e, index)}
                />

                {/* <DescriptionTypes discriptionType={discriptionType} /> */}

                <div className="col-md-4">
                  <label htmlFor="name" className="fs-6">
                    Type:
                  </label>
                  <br />
                  <Form.Select
                    id="subCategory"
                    aria-label="Default select example"
                    className="my-2"
                    value={selectedDescriptionMethod}
                    onChange={(e) =>
                      setSelectedDescriptionMethod(e.target.value)
                    }
                  >
                    <option value="">Select Description Method</option>
                    {Array.isArray(discriptionMethod) &&
                      discriptionMethod.map((method) => (
                        // console.log("method",method)
                        <option key={method._id} value={method.name}>
                          {method.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>

                {/*  */}
                <div className="col-md-4">
                  <div className="my-2">
                    {selectedDescriptionMethod.trim() === "Drop Down" && (
                      <div>
                        <label htmlFor="name" className="fs-6">
                          Description Type:
                        </label>
                        <br />
                        <SelectDescriptionType
                          discriptionType={discriptionType}
                          value={selectedDescriptionType}
                          onChange={setSelectedDescriptionType}
                        />
                      </div>
                    )}

                    {selectedDescriptionMethod.trim() === "Check Box" && (
                      <div>
                        <label htmlFor="name" className="fs-6">
                          Description Type:
                        </label>
                        <br />
                        <SelectDescriptionType
                          discriptionType={discriptionType}
                          value={selectedDescriptionType}
                          onChange={setSelectedDescriptionType}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/*  */}

                {/* add field */}
                <span>
                  {inputFields.length - 1 === index && (
                    <button
                      variant="contained"
                      size="small"
                      sx={{ ml: 2 }}
                      type="button"
                      className="bg-primary m-2 mx-4 text-light p-1 position-absolute"
                      onClick={onAddClick}
                      style={{ right: "0", top: "0" }}
                    >
                      Add
                    </button>
                  )}
                </span>
                {/* add field */}

                {/* Remove field */}
                <span>
                  {inputFields.length > 0 && inputFields.length !== 1 && (
                    <button
                      variant="contained"
                      size="small"
                      color="error"
                      sx={{ ml: 2 }}
                      onClick={() => onRemoveClick(index)}
                    >
                      Remove
                    </button>
                  )}
                </span>
                {/* Remove field */}
              </div>
            ))}
          </div>

          <div className="buttons">
            <button>Save</button>
            <button type="button" onClick={() => setShow(false)}>
              Cancel
            </button>
          </div>
        </div>
      </form>
      {isSuccess && <p>Description added successfully!</p>}
    </div>
  );
}

export default UnderSubCategory;
