import React from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {API_BASE_URL} from "../../utils/index"


export default function DescriptionNameTypeSubCategorys({
  selectedSubCategory,
  setSelectedSubCategory,
  subCategory,
  setDescriptionNameType,
}) {
  const handleSubCategorySelect = (e) => {
    const selectedSubCategory = e.target.value;
    setSelectedSubCategory(selectedSubCategory);

    // Fetch subcategories based on the selected category
    axios
      .get(
        `${API_BASE_URL}/description/admin/descriptionnames/${selectedSubCategory}`
      )
      .then((response) => {
        setDescriptionNameType(response.data.discriptionnamesbysubcategory);
        // console.log(response.data.discriptionnamesbysubcategory, "New descriptionnames");
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Sub Category Name:
      </label>
      <br />
      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedSubCategory}
        onChange={handleSubCategorySelect}
      >
        <option value="">Select a Sub category</option>
        {Array.isArray(subCategory) ? (
          subCategory.map((subcategory) => (
            <option key={subcategory._id} value={subcategory.name}>
              {subcategory.name}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}
