import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";
import {API_BASE_URL} from "../../utils/index"


function DescriptionCategory({
  categories,
  selectedCategory,
  setSelectedCategory,
  setSubCategory,
}) {
  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);

    // Fetch subcategories based on the selected category
    axios
      .get(`${API_BASE_URL}/category/subcategories/${selectedCategory}`)
      .then((response) => {
        setSubCategory(response.data.subcategories);
        // console.log("New subcategory selectedCategory",response.data.subcategories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-6">
      <label htmlFor="name" className="fs-6">
        Category Name:
      </label>
      <br />
      <Form.Select
        id="category"
        aria-label="Default select example"
        className="my-2"
        value={selectedCategory}
        onChange={handleCategorySelect}
      >
        <option value="">Select a category</option>
        {categories.map((category) => (
          <option key={category._id} value={category.name}>
            {category.name}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}

export default DescriptionCategory;
