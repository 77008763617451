import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import SuccessMessage from "../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import CancelButton from "../../../components/Buttons/CancelButton";

function FilterEdit() {
  const { _id } = useParams();
  const [single, setSingle] = useState("");
  const navigate = useNavigate();

  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefilter/${_id}`, { headers })
      .then((response) => {
        setSingle(response.data.filter.name);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const data = { name: single };
    axios
      .put(`${API_BASE_URL}/admin/updatefilter/${_id}`, data, {
        headers,
      })
      .then((response) => {
        SuccessMessage("Update Filter Success");
        navigate("/filtersDetials");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Filter</h2>
      <form onSubmit={handleSave} className="category">
        <div>
          <label htmlFor="name" className="fs-6">
            Change Filter:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={single}
            onChange={(e) => setSingle(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit">Save</button>
          <CancelButton path="/filtersDetials" />
        </div>
      </form>
    </div>
  );
}

export default FilterEdit;
