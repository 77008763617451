import React from "react";
import Form from "react-bootstrap/Form";

export default function DescriptionTypeSubCategorys({
  selectedSubCategory,
  setSelectedSubCategory,
  subCategory,
}) {
  return (
    <div className="col-md-6">
      <label htmlFor="name" className="fs-6">
        Sub Category Name:
      </label>
      <br />
      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedSubCategory}
        onChange={(e) => setSelectedSubCategory(e.target.value)}
      >
        <option value="">Select a Sub category</option>
        {Array.isArray(subCategory) ? (
          subCategory.map((subcategory) => (
            <option key={subcategory._id} value={subcategory._id}>
              {subcategory.name}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}
