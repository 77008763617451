import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {API_BASE_URL} from "../../../utils/index"


function DescriptionNameTypesDetial() {
  const { _id } = useParams();

  const [descriptionTypeDetial, setDescriptionTypeDetial] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singledescriptiontype/${_id}`)
      .then((response) => {
        setDescriptionTypeDetial(response.data.descriptiontype); // Update the category state
        // console.log(response.data.descriptiontype, "singledescription 1234");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Description Type Detial</h2>
      <h6>Description Name:</h6>
      <p>{descriptionTypeDetial.descriptionname}</p>

      <h6>Sub Category Name:</h6>
      <p>{descriptionTypeDetial.subcategory}</p>

      <h6>Category Name:</h6>
      <p>{descriptionTypeDetial.category}</p>
      <h6>Created At</h6>
      <p>{descriptionTypeDetial.createdAt}</p>
    </div>
  );
}

export default DescriptionNameTypesDetial;
