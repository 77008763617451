import React, { useState } from "react";
import loginLogo from "../../Assets/Images/login-logo.png";
import "../../Assets/Images/loginPage-banner.jpg";
import "../../Pages/LoginPage/LoginPage.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import SuccessMessage from "../Messages/SuccessMessage";
import ErrorMessage from "../Messages/ErrorMessage";

export default function Login() {
  const [email, setEmail] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [hide, setHide] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/admin/login`, {
        email: email,
        password: verifyPassword,
      })
      .then((result) => {
        console.log("result Login", result.data);
        navigate("/");

        const adminId = result.data.token;
        const refreshToken = result.data.refreshToken;

        localStorage.setItem("adminId", adminId);
        localStorage.setItem("refreshToken", refreshToken);

        SuccessMessage("Login Success");
      })
      .catch((error) => {
        console.log("error", error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="login-page ">
      <div className="container d-flex align-items-center justify-content-center h-100">
        <form className="right" onSubmit={handleSubmit}>
          <h1 className="">CHENNAI FURNITURE</h1>

          <div className="my-2">
            <input
              className="form-control"
              type="validation"
              name="email"
              id="email"
              placeholder="E-Mail Id"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="my-2 password-input">
            <input
              className="form-control"
              type={hide ? "text" : "password"}
              name="password"
              id="password"
              value={verifyPassword}
              placeholder="Password"
              required
              onChange={(e) => setVerifyPassword(e.target.value)}
            />
            <button type="button" onClick={() => setHide(!hide)}>
              {hide ? "Hide" : "Show"}
            </button>
          </div>

          <div className="my-2">
            <button type="submit" className="btn submit-button">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
