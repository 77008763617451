import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import {API_BASE_URL} from "../../../utils/index"


function UnderSubProductCategory() {
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [underSubProductCategories, setUnderSubProductCategories] = useState(
    []
  );

  useEffect(() => {
    // Fetch category data
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });

    // Fetch subcategory data
    axios
      .get(`${API_BASE_URL}/subcategory/admin/getsubcategory`)
      .then((response) => {
        setSubCategories(response.data.subcategory);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });

    // Fetch under sub product category data
    axios
      .get(`${API_BASE_URL}/undersubcategory/admin/getundersubcategory`)
      .then((response) => {
        setUnderSubProductCategories(response.data.undersubcategories);
        // console.log(response.data.undersubcategories,"setUnderSubProductCategories loki")
      })
      .catch((error) => {
        console.error("Error fetching Under Sub Product Category data:", error);
      });
  }, []);

  // Function to delete a category start
  const deleteCategory = (categoryId) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this Under Sub Category?"
    );

    if (shouldDelete) {
      axios
        .delete(
          `${API_BASE_URL}/undersubcategory/admin/deleteundersubcategory/${categoryId}`
        )
        .then(() => {
          setUnderSubProductCategories((prevData) =>
            prevData.filter((item) => item._id !== categoryId)
          );
        })
        .catch((error) => {
          console.error("Error deleting Under Sub Category:", error);

          if (error.response) {
            // Log the response data and status code for more information
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
          }
        });
    }
  };
  // // Function to delete a category end

  const toggleCategoryStatus = (categoryId, isActive) => {
    // Make a PUT request to your API to update the category status
    axios
      .put(`${API_BASE_URL}/undersubcategory/admin/status/${categoryId}`, {
        isActive: !isActive, // Toggle the active status
      })
      .then(() => {
        // Update the local state to reflect the change
        setUnderSubProductCategories((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        if (error.response) {
          // Log the response data and status code for more information
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Description</h2>
        <Link to="/underSubCategory" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table">
        <thead>
          <tr>
            <th>Description Name</th>
            <th>Description type</th>
            <th>Description Method</th>
            <th>Sub Categories</th>
            <th>Categories</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {underSubProductCategories && underSubProductCategories.length > 0 ? (
            underSubProductCategories.map((underSubCategory, index) => (
              // console.log(underSubCategory,"underSubCategory test")
              <tr key={index}>
                <td>{underSubCategory.undersubcategoryname}</td>

                <td>{underSubCategory.discriptiontypes}</td>

                <td>{underSubCategory.discriptionmethod}</td>

                <td>
                  {subCategories.map((subCategory) => {
                    console.log(subCategory, "subCategory test");
                    if (subCategory.name === underSubCategory.subcategoryname) {
                      return subCategory.name;
                    }
                    return null;
                  })}
                </td>

                <td>
                  {categories.map((category) => {
                    if (category.name === underSubCategory.category) {
                      return category.name;
                    }
                    return null;
                  })}
                </td>

                <td>
                  <Link to={`/underSubCategorysDetial/${underSubCategory._id}`}>
                    <button className="mx-1">
                      <AiFillEye className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() => deleteCategory(underSubCategory._id)}
                  >
                    <AiFillDelete className="m-2 fs-6" />
                  </button>

                  <Link
                    to={`/editUnderSubProductCategory/${underSubCategory._id}`}
                  >
                    <button className="mx-1">
                      <FiEdit className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() =>
                      toggleCategoryStatus(
                        underSubCategory._id,
                        underSubCategory.isActive
                      )
                    }
                  >
                    {underSubCategory.isActive ? (
                      <BsToggleOn className="m-2 fs-6" />
                    ) : (
                      <BsToggleOff className="m-2 fs-6" />
                    )}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Under subcategories found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default UnderSubProductCategory;
