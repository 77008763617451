import React, { useState } from "react";
import loginLogo from "../../Assets/Images/login-logo.png";
import "../../Assets/Images/loginPage-banner.jpg";
import "../../Pages/LoginPage/LoginPage.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../../utils/index"


function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      email: email,
      password: verifyPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    axios
      .post(`${API_BASE_URL}/admin/registration`, {
        name: name,
        email: email,
        password: verifyPassword,
      })
      .then((result) => {
        console.log("result Register", result);
        navigate("/login");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="login-page  d-flex align-items-center ">
      <div className="container ">
        <div className="row ">
          <div className="col-md-6">
            <div className="login-logor text-center">
              {/* <img src={loginLogo} alt="loginLogo" /> */}
            </div>
          </div>
          <div className="col-md-6">
            <form className="right " onSubmit={handleSubmit}>
              <p className="login-title">Register</p>

              <div className="my-2">
                <input
                  className="form-control"
                  type="validation"
                  name="name"
                  id="name"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="my-2">
                <input
                  className="form-control"
                  type="validation"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="my-2">
                <input
                  className="form-control"
                  type="password"
                  name="Password"
                  id="password"
                  value={verifyPassword}
                  placeholder="password"
                  onChange={(e) => setVerifyPassword(e.target.value)}
                />
              </div>

              <div className="my-2">
                <button type="submit" className="btn submit-button">
                  Login
                </button>
              </div>

              <div>
                <input type="checkbox" className="form-check-input" />
                <lable className="form-check-label px-2">
                  Keep me logged in
                </lable>
              </div>
              <Link to="/login" className="text-light">
                Go to Login
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
