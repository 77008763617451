import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";
import {API_BASE_URL} from "../../utils/index"

export default function DescriptionNameTypeCategorys({
  setSelectedCategory,
  selectedCategory,
  category,
  setSubCategory,
}) {
  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);

    // Fetch subcategories based on the selected category
    axios
      .get(`${API_BASE_URL}/category/subcategories/${selectedCategory}`)
      .then((response) => {
        setSubCategory(response.data.subcategories);
        // console.log(response.data.subcategories, "New subcategory selectedCategory");
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Category Name:
      </label>
      <br />

      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedCategory}
        onChange={handleCategorySelect}
      >
        <option value="">Select a Category</option>
        {Array.isArray(category) ? (
          category.map((category) => (
            <option key={category._id} value={category.name}>
              {category.name}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}
