import React from "react";
import { Link } from "react-router-dom";

function CancelButton(data) {
  return (
    <Link to={data.path}>
      <button>Cancel</button>
    </Link>
  );
}

export default CancelButton;