import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const Header = () => {
  const [open, setopen] = useState(false);

  const handleClick = () => {
    setopen(!open);
  };
  
  return (
    <div className="d-flex align-items-start">
      <Sidebar  />
      <div className="w-100">
        <Navbar />
        <div className="layout-body">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Header;