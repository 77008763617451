import React, { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import SuccessMessage from "../../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import CancelButton from "../../../../components/Buttons/CancelButton";

function FiltersTypes() {
  const [filter, setFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const navigate = useNavigate();

  const headers = useAuthHeaders();

  useEffect(() => {
    // Fetch category data from your API
    axios
      .get(`${API_BASE_URL}/admin/getallfilter`, { headers })
      .then((response) => {
        // console.log("FiltersTypes", response.data.filters);
        setFilter(response.data.filters);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  console.log("selectedFilter", selectedFilter);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (tags.length === 0 || !selectedFilter) {
      console.error("Tags and selected filter are required!");
      return;
    }

    axios
      .post(
        `${API_BASE_URL}/admin/newfiltertype`,
        {
          filter: selectedFilter,
          filter_Types: textValues,
        },
        {
          headers,
        }
      )
      .then((response) => {
        setIsSuccess(true);
        navigate("/editFiltersTypes");
        SuccessMessage("Create Filter Types");
        setTags("");
        setSelectedFilter("");
      })
      .catch((error) => {
        console.error("Error adding sub-category:", error);
        setIsSuccess(false);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-4">
      <h2>Filters Types</h2>
      <form onSubmit={handleFormSubmit} className="category">
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Select Filter:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              required
            >
              <option value="">Select a Filter</option>
              {filter.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Filter Types:
            </label>
            <br />
            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
              required
            />
          </div>
        </div>
        <div className="buttons">
          <button>Save</button>
          <CancelButton path="/editFiltersTypes" />
        </div>
      </form>
      {isSuccess && (
        <p className="text-danger">Sub category added successfully!</p>
      )}
    </div>
  );
}

export default FiltersTypes;
