import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import {API_BASE_URL} from "../../../utils/index"


function FilterViewPage() {
  const { _id } = useParams();

  const [data, setdata] = useState({});

  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefilter/${_id}`, { headers })
      .then((response) => {
        setdata(response.data.filter); // Update the category state
        // console.log(response.data.category,"response")
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Filter Details</h2>
      <h6>Name:</h6>
      <p>{data.name}</p>
      <h6>createdAt</h6>
      <p>{data.createdAt}</p>
    </div>
  );
}

export default FilterViewPage;
