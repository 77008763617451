import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../../../../utils/index"


function EditDescriptionMethod() {
  const { _id } = useParams();
  const [newEditDescriptionName, setNewEditDescriptionName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/formfieldcategory/admin/singleformfieldcategory/${_id}`
      )
      .then((response) => {
        setNewEditDescriptionName(response.data.formfieldcategory.name);
        // console.log(response.data.formfieldcategory.name)
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const data = { name: newEditDescriptionName };
    axios
      .put(
        `${API_BASE_URL}/formfieldcategory/admin/updateformfieldcategory/${_id}`,
        data
      )
      .then((response) => {
        // console.log("response :", response);
        navigate("/descriptionType");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Under Sub Category</h2>
      <form onSubmit={handleSave} className="category">
        <div>
          <label htmlFor="name" className="fs-6">
            Change Under Sub Category Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={newEditDescriptionName}
            onChange={(e) => setNewEditDescriptionName(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit">Save</button>
          <button>Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EditDescriptionMethod;
