import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {API_BASE_URL} from "../../../../utils/index"


function UnderSubProductCategoryDetial() {
  const { _id } = useParams();

  const [underSubProductCategoryDetial, setUnderSubProductCategoryDetial] =
    useState({});

  console.log(underSubProductCategoryDetial, "underSubProductCategoryDetial");

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/undersubcategory/admin/singleundersubcategory/${_id}`
      )
      .then((response) => {
        setUnderSubProductCategoryDetial(response.data.undersubcategory);
        // console.log(response.data.undersubcategory,"response")
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Under subCategory Details</h2>
      <h5>Under subCategory Name:</h5>
      <p>{underSubProductCategoryDetial.undersubcategoryname}</p>
      <h5>SubCategory Name:</h5>
      <p>{underSubProductCategoryDetial.subcategoryname}</p>
      <h5>createdAt</h5>
      <p>{underSubProductCategoryDetial.createdAt}</p>
    </div>
  );
}

export default UnderSubProductCategoryDetial;
