import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";
import {API_BASE_URL} from "../../utils/index"


function DescriptionName({
  discriptionName,
  setSelectedDiscriptionName,
  selectedDiscriptionName,
  setDiscriptionType,
}) {
  const handleDescriptionNameSelect = (e) => {
    const selectedDiscriptionName = e.target.value;
    setSelectedDiscriptionName(selectedDiscriptionName);

    // Fetch subcategories based on the selected category
    axios
      .get(
        `${API_BASE_URL}/admin/getdescriptiontypebydescriptionname/${selectedDiscriptionName}`
      )
      .then((response) => {
        setDiscriptionType(response.data.descriptiontypes);
        // console.log("New selectedCategory",response.data.descriptiontypes);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Description Name:
      </label>
      <br />
      <Form.Select
        id="subCategory"
        aria-label="Default select example"
        className="my-2"
        value={selectedDiscriptionName}
        onChange={handleDescriptionNameSelect}
      >
        <option value="">Select a subcategory</option>

        {Array.isArray(discriptionName) ? (
          discriptionName.map((category) => (
            // console.log(category,"category")
            <option key={category._id} value={category.descriptionName}>
              {category.descriptionName}
            </option>
          ))
        ) : (
          <option value="">Loading subcategories...</option>
        )}
      </Form.Select>
    </div>
  );
}

export default DescriptionName;
