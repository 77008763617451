import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function EditFiltersTypes() {
  const [filter, setFilter] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);

  const headers = useAuthHeaders();

  useEffect(() => {
    // Fetch category data
    axios
      .get(`${API_BASE_URL}/admin/getallfilter`, { headers })
      .then((response) => {
        setFilter(response.data.filters);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });

    // Fetch subcategory data
    axios
      .get(`${API_BASE_URL}/admin/getallfiltertypes`)
      .then((response) => {
        setFilterTypes(response.data.filterstypes);
        // console.log("responser", response.data.filterstypes)
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const deleteCategory = (categoryId) => {
    const shouldDelete = Swal.fire({
      title: "Are you sure you want to delete this Filters Types?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Delete!", "", "success");
        if (shouldDelete) {
          axios
            .delete(`${API_BASE_URL}/admin/deletefiltertype/${categoryId}`)
            .then(() => {
              setFilterTypes((prevData) =>
                prevData.filter((item) => item._id !== categoryId)
              );
            })
            .catch((error) => {
              console.error("Error deleting category:", error);

              if (error.response) {
                // Log the response data and status code for more information
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
              }
            });
        }
      }
    });
  };
  // // // Function to delete a category end

  const toggleCategoryStatus = (categoryId, isActive) => {
    // Make a PUT request to your API to update the category status
    axios
      .put(
        `${API_BASE_URL}/admin/filtertypestatus/${categoryId}`,
        {
          isActive: !isActive, // Toggle the active status
        },
        {
          headers,
        }
      )
      .then(() => {
        // Update the local state to reflect the change
        setFilterTypes((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        if (error.response) {
          // Log the response data and status code for more information
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      });
  };

  console.log("filterTypes", filterTypes);

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Filters Types</h2>
        <Link to="/filtersTypes" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table">
        <thead>
          <tr>
            <th>Filters Types</th>
            <th>Filters</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filterTypes && filterTypes.length > 0 ? (
            filterTypes.map((item, index) => (
              <tr key={index}>
                <td>
                  <DropdownButton id="dropdown-item-button" title="">
                    {item.filter_Types.map((innerItem, i) => {
                      return (
                        <Dropdown.Item key={i} as="button">
                          {innerItem}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </td>
                <td>{item.filter?.name}</td>
                <td>
                  <Link to={`/viewFiltersTypes/${item._id}`}>
                    <button className="mx-1">
                      <AiFillEye className="m-2 fs-6" />
                    </button>
                  </Link>
                  <button
                    className="mx-1"
                    onClick={() => deleteCategory(item._id)}
                  >
                    <AiFillDelete className="m-2 fs-6" />
                  </button>
                  <Link to={`/updateFiltersTypes/${item._id}`}>
                    <button className="mx-1">
                      <FiEdit className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() =>
                      toggleCategoryStatus(item._id, item.isActive)
                    }
                  >
                    {item.isActive ? (
                      <BsToggleOn className="m-2 fs-6" />
                    ) : (
                      <BsToggleOff className="m-2 fs-6" />
                    )}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Filter Types Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EditFiltersTypes;
