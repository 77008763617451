import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/index";
import Form from "react-bootstrap/Form";
import SuccessMessage from "../../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import CancelButton from "../../../../components/Buttons/CancelButton";

function EditSubCategory() {
  const { _id } = useParams();
  const [newSubCategoryName, setNewSubCategoryName] = useState("");
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [selectedCurrentCategory, setSelectedCurrentCategory] = useState("");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/subcategory/admin/singlesubcategory/${_id}`)
      .then((response) => {
        setNewSubCategoryName(response.data.subcategory.name);
        setSelectedCurrentCategory(response.data.subcategory.categoryId);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });

    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    const data = {
      name: newSubCategoryName,
      categoryId: selectedCurrentCategory,
    };

    axios
      .put(`${API_BASE_URL}/subcategory/admin/updatesubcategory/${_id}`, data)
      .then((response) => {
        console.log("response :", response);
        SuccessMessage("Update Sub Category");
        navigate("/subCategory");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Sub Category</h2>
      <form onSubmit={handleSave} className="category">
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategory || selectedCurrentCategory}
              required
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                // console.log(category,"category")
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Change Sub Category Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={newSubCategoryName}
              onChange={(e) => setNewSubCategoryName(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <button type="submit">Save</button>
          <CancelButton path="/subproductcategory" />
        </div>
      </form>
    </div>
  );
}

export default EditSubCategory;
