import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/index"


function EditDescriptionNameTypesDetial() {
  const { _id } = useParams();
  const [newEditDescriptionNameType, setNewEditDescriptionNameType] =
    useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singledescriptiontype/${_id}`)
      .then((response) => {
        console.log("response _id", response.data.descriptiontype);
        setNewEditDescriptionNameType(response.data.descriptiontype);
        
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    axios
      .put(`${API_BASE_URL}/admin/updatedescriptiontype/${_id}`, {
        descriptionname: newEditDescriptionNameType,
      })
      .then((response) => {
        navigate("/viewDescriptionNameTypes");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Description Type</h2>
      <form onSubmit={handleSave} className="category">
        <div>
          <label htmlFor="name" className="fs-6">
            Change Description Type Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={newEditDescriptionNameType.descriptionname}
            onChange={(e) => setNewEditDescriptionNameType(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit">Save</button>
          <button>Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EditDescriptionNameTypesDetial;
