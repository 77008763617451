import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";

function DescriptionType() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getdescriptions`)
      .then((response) => {
        setData(response.data.description);
        console.log("getdescriptions", response.data.description);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const deleteCategory = (categoryId) => {
    const shouldDelete = Swal.fire({

      title: "Are you sure you want to delete this Desription Name ?",
      showCancelButton: true,
      confirmButtonText: "Delete",

    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Delete!", "", "success");
        if (shouldDelete) {
          axios
            .delete(`${API_BASE_URL}/admin/deletedescription/${categoryId}`)
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item._id !== categoryId)
              );
            })
            .catch((error) => {
              console.error("Error deleting category:", error);

              if (error.response) {
                // Log the response data and status code for more information
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
              }
            });
        }
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    
    axios
      .put(
        `${API_BASE_URL}/admin/statusdescription/${categoryId}`,
        {
          isActive: !isActive, 
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Description Name</h2>
        <Link to="/createDescriptionType" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Sub Category</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.descriptionName}</td>
              <td> {item?.subcategory?.name} </td>
              <td> {item?.category?.name} </td>
              <td>
                <Link to={`/descriptionTypeDetial/${item._id}`}>
                  <button className="mx-1">
                    <AiFillEye className="m-2 fs-6" />
                  </button>
                </Link>
                <button
                  className="mx-1"
                  onClick={() => deleteCategory(item._id)}
                >
                  <AiFillDelete className="m-2 fs-6" />
                </button>

                <Link to={`/editDescriptionType/${item._id}`}>
                  <button className="mx-1">
                    <FiEdit className="m-2 fs-6" />
                  </button>
                </Link>

                <button
                  className="mx-1"
                  onClick={() => toggleCategoryStatus(item._id, item.isActive)}
                >
                  {item.isActive ? (
                    <BsToggleOn className="m-2 fs-6" />
                  ) : (
                    <BsToggleOff className="m-2 fs-6" />
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DescriptionType;
