import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error("Error caught by error boundary:", error, info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="m-4">
          <h2>Oops! Something went wrong.</h2>
          <p>We're sorry, but there was an error loading this content.</p>
          <p>Please try refreshing the page or check back later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;