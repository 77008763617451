import React from "react";
import Form from "react-bootstrap/Form";
import DescriptionMethodConditions from "../../../components/DescriptionMethodConditions/DescriptionMethodConditions";
import "react-datepicker/dist/react-datepicker.css";
import { CiCircleRemove } from "react-icons/ci";
import DescriptionTypesField from "../../../components/DescriptionNameType/DescriptionTypesField";

function NewProductsStaticField({
  setPartNumber,
  onImagesChange,
  removeImage,
  ProductsImagesPreview,
  setManuFacturer,
  setOriginalPrice,
  setSalelPrice,
  handleCategorySelect,
  selectedCategory,
  categories,
  handleSubCategorySelect,
  selectedSubCategory,
  subCategory,
  discriptionType,
  handleChange,
  setManualInput,
  manualInput,
  setStock,
  setColor,
  tags,
  setTags,
  setArivel,
  arrivel,
}) {
  return (
    <div className="row">
      <div className="p-2 col-md-4">
        <label htmlFor="product_name" className="fs-6">
          Product Name :
        </label>
        <br />
        <input
          type="text"
          id="product_name"
          placeholder="Product Name"
          className="w-100"
          required
          onChange={(e) => setPartNumber(e.target.value)}
        />
      </div>

      <div className="p-2 col-md-4">
        <label htmlFor="productimages" className="fs-6">
          Upload Product Images :
        </label>
        <br />
        <input
          type="file"
          name="productimages"
          className="custom-file-input w-100"
          id="productimages"
          multiple
          required
          onChange={onImagesChange}
        />

        <div className="d-flex align-items-center flex-wrap">
          {ProductsImagesPreview.map((productimages, index) => (
            <div key={index} className="mt-3 mr-2">
              <img
                src={productimages}
                alt={`Image Preview`}
                width="55"
                height="52"
                multiple
                required
              />

              <span className="fs-6" onClick={() => removeImage(index)}>
                <CiCircleRemove />
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="col-md-4 p-2">
        <label htmlFor="category_name" className="fs-6">
          Category Name:
        </label>
        <br />
        <Form.Select
          id="category_name"
          aria-label="Default select example"
          className="my-2"
          required
          value={selectedCategory}
          onChange={handleCategorySelect}
        >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </Form.Select>
      </div>

      <div className="col-md-4 p-2">
        <label htmlFor="SubCategory_name" className="fs-6">
          Sub Category Name:
        </label>
        <br />
        <Form.Select
          id="SubCategory_name"
          aria-label="Default select example"
          className="my-2"
          value={selectedSubCategory}
          required
          onChange={handleSubCategorySelect}
        >
          <option value="">Select a Sub Category</option>
          {Array.isArray(subCategory) ? (
            subCategory.map((category) => (
              // console.log("sebcategory test :", category)
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))
          ) : (
            <option value="">Loading subcategories...</option>
          )}
        </Form.Select>
      </div>

      {/* dynamic fields */}
      <DescriptionMethodConditions
        dataList={discriptionType}
        setManualInput={setManualInput}
        manualInput={manualInput}
      />
      {/* dynamic fields */}

      {/* Manu Facturer */}
      <div className="p-2 col-md-4">
        <label htmlFor="manuFacturer" className="fs-6">
          ManuFacturer :
        </label>
        <br />
        <input
          type="text"
          id="manuFacturer"
          className="w-100"
          required
          onChange={(e) => setManuFacturer(e.target.value)}
        />
      </div>

      {/* Stock */}
      <div className="p-2 col-md-4">
        <label htmlFor="stock" className="fs-6">
          Stock :
        </label>
        <br />
        <input
          type="number"
          id="stock"
          className="w-100"
          placeholder="0"
          required
          onWheel={(event) => event.currentTarget.blur()}
          onChange={(e) => setStock(e.target.value)}
        />
      </div>

      <div className="p-2 col-md-4 d-flex align-items-center">
        <input type="checkbox" id="Arivel" style={{ width: "10%" }} />

        <label
          htmlFor="Arivel"
          className="fs-6 fw-medium"
          style={{ cursor: "pointer" }}
          onClick={() => setArivel(!arrivel)}
        >
          Add Arivel
        </label>
      </div>

      <div
        className="p-2 col-md-4 border border-2"
        style={{ marginBottom: "10px" }}
      >
        <label htmlFor="stock" className="fs-6">
          Product Color :
        </label>
        <br />
        <DescriptionTypesField setTags={setTags} tags={tags} />
      </div>

      {/* cad */}
      <div className="p-2 col-md-4">
        <label htmlFor="original_price" className="fs-6">
          Original Price :
        </label>
        <br />
        <input
          type="number"
          id="original_price"
          placeholder="0"
          className="w-100"
          required
          onWheel={(event) => event.currentTarget.blur()}
          onChange={(e) => setOriginalPrice(e.target.value)}
        />
      </div>

      <div className="p-2 col-md-4">
        <label htmlFor="sale_price" className="fs-6">
          Sale Price :
        </label>
        <br />
        <input
          type="number"
          placeholder="0"
          id="sale_price"
          className="w-100"
          required
          onWheel={(event) => event.currentTarget.blur()}
          onChange={(e) => setSalelPrice(e.target.value)}
        />
      </div>
    </div>
  );
}

export default NewProductsStaticField;
