import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import axios from "axios";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import Form from "react-bootstrap/Form";
import SuccessMessage from "../../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import CancelButton from "../../../../components/Buttons/CancelButton";

function UpdateFiltersTypes() {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState([]);
  const [currentfilter, setCurrentFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);

  const headers = useAuthHeaders();

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefiltertype/${_id}`, {
        headers,
      })
      .then((response) => {
        setTags(
          response.data.filtertype.filter_Types.map((tag) => ({
            id: tag,
            text: tag,
          }))
        );
        setCurrentFilter(response.data.filtertype.filter);
        // console.log("singlefilter types",response.data.filtertype.filter)
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${API_BASE_URL}/admin/getallfilter`, { headers })
      .then((response) => {
        // console.log("FiltersTypes", response.data.filters);
        setFilter(response.data.filters);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const data = { filter_Types: textValues, filter: selectedFilter };
    axios
      .put(`${API_BASE_URL}/admin/updatefiltertype/${_id}`, data, {
        headers,
      })
      .then((response) => {
        navigate("/editFiltersTypes");
        SuccessMessage("Update Filter Types");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Filter Types</h2>
      <form onSubmit={handleSave} className="category">
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Select Filter:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedFilter}
              required
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <option>Select a Filter</option>
              {filter.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Filter Types:
            </label>
            <br />
            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
            />
          </div>
        </div>
        <div className="buttons">
          <button>Save</button>
          <CancelButton path="/editFiltersTypes" />
        </div>
      </form>
    </div>
  );
}

export default UpdateFiltersTypes;
