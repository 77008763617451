import React from 'react'
import Header from "./components/Headers/Header" 
import Footer from "./components/Footer/Footer"

function Layout() {
  return (
    <>
      <Header /> 
      {/* <Footer /> */}
    </>
  )
}

export default Layout 