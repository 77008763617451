import React, { useState } from "react";
import "../ProductCategory/productCategory.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import SuccessMessage from "../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import { useAuthHeaders } from "../../../components/Token";
import CancelButton from "../../../components/Buttons/CancelButton";

function ProductCategory() {
  const [name, setName] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const headers = useAuthHeaders();

  const handleCategoryCreation = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/category/admin/newcategory`,
        {
          name: name,
        },
        { headers }
      )
      .then((result) => {
        console.log(result);
        SuccessMessage("Create Category success");
        navigate("/category");
      })
      .catch((error) => {
        console.log(error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-3 ">
      <h2>Create Category</h2>
      <form
        action=""
        className="category row"
        onSubmit={handleCategoryCreation}
      >
        <div className="col-md-6">
          <label htmlFor="name" className="fs-6">
            Create Category Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="buttons">
          
          <button type="submit" value="send">
            Save
          </button>
          <CancelButton path="/category" />
        </div>
      </form>
    </div>
  );
}

export default ProductCategory;
