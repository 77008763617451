import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Commom/Commom.scss";
import axios from "axios";
import { Toast } from "react-bootstrap";
import NewProductsStaticField from "./NewProductsStaticField";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import SuccessMessage from "../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Spinner from "react-bootstrap/Spinner";
import CancelButton from "../../../components/Buttons/CancelButton";

function NewProducts() {
  const [partNumber, setPartNumber] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [submitCategories, setSubmitCategories] = useState("");

  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [submitSubCategories, setSubmitSubCategories] = useState("");

  const [show, setShow] = useState(false);
  const [productsImages, setProductsImages] = useState([]);
  const [ProductsImagesPreview, setProductsImagesPreview] = useState([]);
  const [manuFacturer, setManuFacturer] = useState();
  const [originalPrice, setOriginalPrice] = useState();
  const [products, setProducts] = useState();
  const [salePrice, setSalelPrice] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [icon, setIcon] = useState([]);
  const [stock, setStock] = useState();
  const [color, setColor] = useState();
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);
  const [loader, setLoader] = useState(false);
  const [arrivel, setArivel] = useState(false);

  const navigate = useNavigate();

  const sampleTost = (data) => {
    return (
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Body>{data}</Toast.Body>
      </Toast>
    );
  };

  const [discriptionType, setDiscriptionType] = useState("");
  const [selectDiscriptionType, setSelectDiscriptionType] = useState([]);

  const defaultData = [{}];
  const [manualInput, setManualInput] = useState(defaultData);

  const handleChange = (ele) => {
    const { name, value } = ele.target;
    setSelectDiscriptionType((prev) => ({ ...prev, [name]: value }));
  };

  const headers = useAuthHeaders();

  const handleproductCreation = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (tags.length === 0) {
      Swal.fire({
        // title: "The Internet?",
        text: "Please Add Product Color",
        icon: "question",
      });
      return;
    }

    productsImages.forEach((productsImage) => {
      formData.append("productimages", productsImage);
    });

    icon.forEach((icon) => {
      formData.append("cadFile", icon);
    });

    formData.append("productName", partNumber);
    formData.append("categoryname", submitCategories);
    formData.append("subcategoryname", submitSubCategories);
    formData.append("manufacturer", manuFacturer);
    formData.append("originalprice", originalPrice);
    formData.append("saleprice", salePrice);
    formData.append("stock", stock);
    formData.append("newarrival", arrivel);

    textValues.forEach((color, index) => {
      formData.append(`Productcolor[${index}]`, color);
    });

    // const formattedDescriptions = Object.entries(selectDiscriptionType).map(
    //   ([name, value]) => ({
    //     name: name ?? "Default Name",
    //     value: value ?? "Default Value",
    //   })
    // );

    // formattedDescriptions.forEach((description, index) => {
    //   formData.append(
    //     `description[${index}][name]`,
    //     description.name || "loki"
    //   );

    //   formData.append(
    //     `description[${index}][value]`,
    //     description.value || "loki"
    //   );
    // });

    const validManualInputs = manualInput.filter(
      (input) => typeof input === "object"
    );

    const formattedDescriptionsManualInput = validManualInputs.map(
      (item, index) => {
        if (item && typeof item === "object") {
          const keys = Object.keys(item);
          const values = Object.values(item);

          return {
            name: keys.length > 0 ? keys[0] : "Default Name",
            value: values.length > 0 ? values[0] : "Default Value",
          };
        }

        return {
          name: "Default Name",
          value: "Default Value",
        };
      }
    );

    validManualInputs.forEach((value, index) => {
      const keys = Object.keys(value);

      const nameToAppend = keys[0] || "";
      const valueToAppend = value[keys[0]] || "";

      if (nameToAppend.trim() !== "") {
        formData.append(
          `description[${
            formattedDescriptionsManualInput.length + index
          }][name]`,
          nameToAppend
        );
        formData.append(
          `description[${
            formattedDescriptionsManualInput.length + index
          }][value]`,
          valueToAppend
        );
      }
    });

    // validManualInputs.forEach((value, index) => {
    //   const keys = Object.keys(value);

    //   const nameToAppend = keys[0] || "";
    //   const valueToAppend = value[keys[0]] || "";

    //   // Check if name is not empty before appending
    //   if (nameToAppend.trim() !== "") {
    //     formData.append(
    //       `description[${
    //         formattedDescriptionsManualInput.length + index
    //       }][name]`,
    //       nameToAppend
    //     );
    //     formData.append(
    //       `description[${
    //         formattedDescriptionsManualInput.length + index
    //       }][value]`,
    //       valueToAppend
    //     );
    //   }
    // });

    setLoader(true);

    axios
      .post(`${API_BASE_URL}/admin/newproducts`, formData, {
        headers,
      })
      .then((result) => {
        // setShow(true);
        SuccessMessage("Create Product Success");
        navigate("/newproductsViewPage");
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        ErrorMessage(error);
        setLoader(false);
      });
  };

  const onImagesChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState == 2) {
          setProductsImagesPreview((oldArray) => [...oldArray, reader.result]);
          setProductsImages((oldArray) => [...oldArray, file]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    sampleTost("sample testing");
    // Fetch categories
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;

    setSelectedCategory(selectedCategory);

    const findCategoryName = categories.find(
      (item) => item._id === selectedCategory
    );

    setSubmitCategories(findCategoryName?.name);

    axios
      .get(`${API_BASE_URL}/category/subcategories/${selectedCategory}`)
      .then((response) => {
        setSubCategory(response.data.subcategories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  const handleSubCategorySelect = (e) => {
    const selectedSubCategory = e.target.value;
    setSelectedSubCategory(selectedSubCategory);

    const findSubCategoryName = subCategory.find(
      (item) => item._id === selectedSubCategory
    );

    setSubmitSubCategories(findSubCategoryName?.name);

    axios
      .get(`${API_BASE_URL}/admin/descriptionnames/${selectedSubCategory}`)
      .then((response) => {
        setDiscriptionType(response.data.discriptionnamesbysubcategory);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  // const removeImage = (index) => {
  //   setProductsImagesPreview((prevImages) =>
  //     prevImages.filter((_, i) => i !== index)
  //   );
  //   setProductsImages((prevImages) => prevImages.filter((_, i) => i !== index));

  //   const inputElement = document.getElementById("productimages");

  //   if (inputElement) {
  //     inputElement.value = null;
  //   }
  // };

  const removeImage = (index) => {
    setProductsImagesPreview((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );

    setProductsImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Create New Products</h2>
      </div>
      <form
        action="submit"
        className="category"
        onSubmit={handleproductCreation}
      >
        <NewProductsStaticField
          setPartNumber={setPartNumber}
          onImagesChange={onImagesChange}
          removeImage={removeImage}
          ProductsImagesPreview={ProductsImagesPreview}
          setManuFacturer={setManuFacturer}
          setOriginalPrice={setOriginalPrice}
          setProducts={setProducts}
          setSalelPrice={setSalelPrice}
          handleCategorySelect={handleCategorySelect}
          selectedCategory={selectedCategory}
          categories={categories}
          handleSubCategorySelect={handleSubCategorySelect}
          selectedSubCategory={selectedSubCategory}
          subCategory={subCategory}
          discriptionType={discriptionType}
          handleChange={handleChange}
          setManualInput={setManualInput}
          manualInput={manualInput}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          setIcon={setIcon}
          setStock={setStock}
          setColor={setColor}
          setTags={setTags}
          tags={tags}
          setArivel={setArivel}
          arrivel={arrivel}
        />

        <div className="buttons">
          {loader === true ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <button type="submit" value="send">
              Save
            </button>
          )}

          <CancelButton path="/newproductsViewPage" />
        </div>

        {show && (
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
            className="bg-success text-light"
          >
            <Toast.Body>Product created successfully!</Toast.Body>
          </Toast>
        )}
      </form>
    </div>
  );
}

export default NewProducts;
