import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../../../../utils/index"


function EditUnderSubProductCategory() {
  const { _id } = useParams();
  const [descriptionName, setDescriptionName] = useState("");
  const [descriptionType, setDescriptionType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/undersubcategory/admin/singleundersubcategory/${_id}`
      )
      .then((response) => {
        const data = response.data.undersubcategory;
        setDescriptionName(data.undersubcategoryname);
        setDescriptionType(data.discription);
        console.log(data, "response");
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, [_id]);

  const handleSave = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const data = {
      undersubcategoryname: descriptionName,
      discription: descriptionType, // Change the field name to "discription"
    };
    axios
      .put(
        `${API_BASE_URL}/undersubcategory/admin/updateundersubcategory/${_id}`,
        data
      )
      .then((response) => {
        console.log("response :", response);
        navigate("/underSubProductCategory");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Under Sub Category</h2>
      <form onSubmit={handleSave} className="category">
        <div>
          <label htmlFor="name" className="fs-6">
            Change Description Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={descriptionName}
            onChange={(e) => setDescriptionName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="description" className="fs-6">
            Change Description Type:
          </label>
          <br />
          <input
            type="text"
            id="description"
            value={descriptionType}
            onChange={(e) => setDescriptionType(e.target.value)}
          />
        </div>

        <div className="buttons">
          <button type="submit">Save</button>
          <button>Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EditUnderSubProductCategory;
