import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";
import {API_BASE_URL} from "../../utils/index"


function DescriptionSubCategory({
  subCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  setDiscriptionName,
}) {
  const handleCategorySelect = (e) => {
    const selectedSubCategory = e.target.value;
    setSelectedSubCategory(selectedSubCategory);

    // Fetch subcategories based on the selected category
    axios
      .get(
        `${API_BASE_URL}/description/admin/descriptionnames/${selectedSubCategory}`
      )
      .then((response) => {
        setDiscriptionName(response.data.discriptionnamesbysubcategory);
        // console.log("New selectedCategory",response.data.discriptionnamesbysubcategory);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-6">
      <label htmlFor="name" className="fs-6">
        Sub Category Name:
      </label>
      <br />
      <Form.Select
        id="subCategory"
        aria-label="Default select example"
        className="my-2"
        value={selectedSubCategory}
        onChange={handleCategorySelect}
      >
        <option value="">Select a subcategory</option>

        {Array.isArray(subCategory) ? (
          subCategory.map((category) => (
            <option key={category._id} value={category.name}>
              {category.name}
            </option>
          ))
        ) : (
          <option value="">Loading subcategories...</option>
        )}
      </Form.Select>
    </div>
  );
}

export default DescriptionSubCategory;
