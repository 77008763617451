import React, { useState } from "react";
import "../ProductCategory/productCategory.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {API_BASE_URL} from "../../../utils/index"


function DescriptionMethod() {
  const [name, setName] = useState();

  const navigate = useNavigate();

  const handleCategoryCreation = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    axios
      .post(`${API_BASE_URL}/formfieldcategory/admin/newformfieldcategory`, {
        name: name,
      })
      .then((result) => {
        console.log(result);
        navigate("/descriptionMethod");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="p-3">
      <h2>description Method</h2>
      <form action="" className="category" onSubmit={handleCategoryCreation}>
        <div>
          <label htmlFor="name" className="fs-6">
            description Method:
          </label>
          <br />
          <input
            type="text"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submxit" value="send">
            Save
          </button>
          <button>Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default DescriptionMethod;
