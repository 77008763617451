import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SuccessMessage from "../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import DescriptionTypesField from "../../../components/DescriptionNameType/DescriptionTypesField";
import { CiCircleRemove } from "react-icons/ci";
import Form from "react-bootstrap/Form";
import CancelButton from "../../../components/Buttons/CancelButton";
import DescriptionMethodConditions from "../../../components/DescriptionMethodConditions/DescriptionMethodConditions";

function EditNewProducts() {
  const { _id } = useParams();
  const [productName, setProductName] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [findCategory, setFindCategory] = useState("");
  const [submitCategory, setSubmitCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [submitSubCategory, setSubmitSubCategory] = useState("");
  const [manuFacturer, setManuFacturer] = useState("");
  const [stock, setStock] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [ProductsImagesPreview, setProductsImagesPreview] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [updatedDescriptions, setUpdatedDescriptions] = useState([]);
  const [productId, setProductId] = useState("");
  const [newImageSubmit, setNewImageSubmit] = useState([]);
  const [tags, setTags] = useState([]);
  const [newDescription, setNewDescription] = useState([]);
  const textValues = tags.map((item) => item.text);
  const defaultData = [{}];
  const [manualInput, setManualInput] = useState(defaultData);
  const [arrivel, setArivel] = useState(false);

  // console.log("selectedSubCategory", selectedSubCategory);

  const headers = useAuthHeaders();
  const navigate = useNavigate();

  const [reRender, setRender] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });

    axios
      .get(`${API_BASE_URL}/subcategory/admin/getsubcategory`)
      .then((response) => {
        // console.log("check sub category", response.data.subcategory);
        setSubCategory(response.data.subcategory);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });

    axios
      .get(`${API_BASE_URL}/admin/products/adminsingleproduct/${_id}`)
      .then((response) => {
        setSubmitCategory(response.data.product.categoryname);
        setSubmitSubCategory(response.data.product.subcategoryname);
        setFindCategory(response.data.product.categoryname);
        setProductName(response.data.product.productName);
        setManuFacturer(response.data.product.manufacturer);
        setStock(response.data.product.stock);
        setOriginalPrice(response.data.product.originalprice);
        setSalePrice(response.data.product.saleprice);
        setProductImage(response.data.product.productimages);
        setProductId(response.data.product._id);

        setArivel(response.data.product.newarrival);

        const initialDescriptions = response.data?.product?.description || [];
        const filteredDescriptions = Array.isArray(initialDescriptions)
          ? initialDescriptions.filter((description) => description !== null)
          : [];

        setDescriptions(filteredDescriptions);
        setUpdatedDescriptions(filteredDescriptions.map((desc) => desc.value));

        setTags(
          response.data.product.Productcolor.map((tag) => ({
            id: tag,
            text: tag,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reRender]);

  const handleSave = (e) => {
    e.preventDefault();

    const formData = new FormData();

    const data = {
      productName: productName,
      categoryname: submitCategory,
      subcategoryname: submitSubCategory,
      manufacturer: manuFacturer,
      stock: stock,
      originalprice: originalPrice,
      saleprice: salePrice,
      newarrival: arrivel,
    };

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    newImageSubmit.forEach((productsImage) => {
      formData.append("productimages", productsImage);
      console.log("local image", productsImage);
    });

    textValues.forEach((color, index) => {
      formData.append(`Productcolor[${index}]`, color);
    });

    const validManualInputs = manualInput.filter(
      (input) => typeof input === "object"
    );

    const formattedDescriptionsManualInput = validManualInputs.map(
      (item, index) => {
        if (item && typeof item === "object") {
          const keys = Object.keys(item);
          const values = Object.values(item);

          return {
            name: keys.length > 0 ? keys[0] : "Default Name",
            value: values.length > 0 ? values[0] : "Default Value",
          };
        }

        return {
          name: "Default Name",
          value: "Default Value",
        };
      }
    );

    validManualInputs.forEach((value, index) => {
      const keys = Object.keys(value);

      const nameToAppend = keys[0] || "";
      const valueToAppend = value[keys[0]] || "";

      if (nameToAppend.trim() !== "") {
        formData.append(
          `description[${
            formattedDescriptionsManualInput.length + index
          }][name]`,
          nameToAppend
        );
        formData.append(
          `description[${
            formattedDescriptionsManualInput.length + index
          }][value]`,
          valueToAppend
        );
      }
    });

    axios
      .put(`${API_BASE_URL}/admin/products/updateproduct/${_id}`, formData, {
        headers,
      })
      .then((response) => {
        console.log("response updateproduct:", response);
        SuccessMessage("Update Product Success");
        navigate("/newproductsViewPage");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        ErrorMessage(error);
      });
  };

  const handleCategorySelect = (e) => {
    const getValue = e.target.value;

    const selectedCategory = categories.find(
      (category) => category._id === getValue
    );

    setSelectCategory(selectedCategory._id);
    setSubmitCategory(selectedCategory.name);

    axios
      .get(`${API_BASE_URL}/category/subcategories/${getValue}`)
      .then((response) => {
        setSubCategory(response.data.subcategories);
        // console.log("New subcategory :" , response.data.subcategories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  const handleSubCategorySelect = (e) => {
    const getValue = e.target.value;
    setSelectedSubCategory(getValue);

    const findSubCategoryName = subCategory.find(
      (item) => item._id === getValue
    );

    setSubmitSubCategory(findSubCategoryName?.name);

    axios
      .get(`${API_BASE_URL}/admin/descriptionnames/${getValue}`)
      .then((response) => {
        setNewDescription(response.data.discriptionnamesbysubcategory || []);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  const onImagesChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState == 2) {
          setProductsImagesPreview((oldArray) => [...oldArray, reader.result]);
          setNewImageSubmit((oldArray) => [...oldArray, file]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index, image) => {
    const imageId = image._id;

    axios
      .delete(`${API_BASE_URL}/admin/products/${productId}/images/${imageId}`)
      .then((res) => {
        console.log("rss in image delete", res);
        // setProductsImagesPreview((prevImages) =>
        //   prevImages.filter((_, i) => i !== index)
        // );
        setProductImage((prevImages) =>
          prevImages.filter((_, i) => i !== index)
        );
      })
      .catch((error) => {
        console.log("error image remove".error);
      });
  };

  const removeNewImagePreview = (index) => {
    setProductsImagesPreview((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  const handleDescriptionChange = (index, newValue) => {
    setUpdatedDescriptions((prevDescriptions) => {
      const updated = [...prevDescriptions];
      updated[index] = newValue;
      return updated;
    });
  };

  useEffect(() => {
    const findCategoryID = categories.find(
      (item) => item.name === findCategory
    );

    if (findCategoryID) {
      setSelectCategory(findCategoryID?._id);
    }
  }, [findCategory]);

  useEffect(() => {
    const findSubCategoryID = subCategory.find(
      (item) => item.name === submitSubCategory
    );

    console.log("findSubCategoryID", findSubCategoryID);

    if (findSubCategoryID) {
      setSelectedSubCategory(findSubCategoryID?._id);
    }
    
  }, [submitSubCategory, subCategory]);

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <div className="p-3">
      <h2>Edit Product</h2>
      <form onSubmit={handleSave} className="category">
        <div className="row category-field">
          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Product Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="productimages" className="fs-6">
              Change Product Image:
            </label>
            <br />
            <input
              type="file"
              name="productimages"
              id="productimages"
              multiple
              onChange={onImagesChange}
            />

            <div className="d-flex align-items-center flex-wrap">
              {productImage.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.productimage}
                    alt={`Existing Product Image ${index}`}
                    width="60"
                    height="60"
                  />

                  <span onClick={() => removeImage(index, image)}>
                    <CiCircleRemove />
                  </span>
                </div>
              ))}

              {ProductsImagesPreview.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`New Product Image ${index}`}
                    width="50"
                    height="50"
                  />
                  <span onClick={() => removeNewImagePreview(index)}>
                    <CiCircleRemove />
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Category :
            </label>
            <br />
            <Form.Select
              id="category"
              aria-label="Default select example"
              className="my-2"
              required
              value={selectCategory}
              onChange={handleCategorySelect}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Sub Category Name:
            </label>
            <br />
            <Form.Select
              id="category"
              aria-label="Default select example"
              className="my-2"
              value={selectedSubCategory}
              onChange={handleSubCategorySelect}
              required
            >
              <option value="">Select a Sub Category</option>
              {Array.isArray(subCategory) ? (
                subCategory.map((subCategory) => (
                  <option key={subCategory._id} value={subCategory._id}>
                    {subCategory.name}
                  </option>
                ))
              ) : (
                <option value="">Loading subcategories...</option>
              )}
            </Form.Select>
          </div>

          {/* descriptions */}
          {newDescription.length !== 0 ? (
            <DescriptionMethodConditions
              dataList={newDescription}
              manualInput={manualInput}
              setManualInput={setManualInput}
            />
          ) : (
            descriptions.map((description, index) => (
              <div className="col-md-4" key={index}>
                <label htmlFor={`description-${index}`} className="fs-6">
                  {description?.name || null}:
                </label>
                <br />
                <input
                  type="text"
                  id={`description-${index}`}
                  value={description.value}
                  // value={updatedDescriptions[index]}
                  required
                  onChange={(e) =>
                    handleDescriptionChange(index, e.target.value)
                  }
                />
              </div>
            ))
          )}
          {/* descriptions */}

          <div className="col-md-4">
            <label htmlFor="ManuFacturer" className="fs-6">
              ManuFacturer :
            </label>
            <br />
            <input
              type="text"
              id="ManuFacturer"
              value={manuFacturer}
              onChange={(e) => setManuFacturer(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Stock :
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            />
          </div>
          <div className="p-2 col-md-4 d-flex align-items-center">
            <input
              type="checkbox"
              id="Arivel"
              checked={arrivel}
              style={{ width: "10%" }}
            />

            <label
              htmlFor="Arivel"
              className="fs-6 fw-medium"
              style={{ cursor: "pointer" }}
              onClick={() => setArivel(!arrivel)}
            >
              Add Arivel
            </label>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Product Color:
            </label>
            <br />
            <DescriptionTypesField setTags={setTags} tags={tags} />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Original Price :
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={originalPrice}
              onChange={(e) => setOriginalPrice(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Sale Price :
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={salePrice}
              onChange={(e) => setSalePrice(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons my-4">
          <button type="submit">Save</button>
          <CancelButton path="/" />
        </div>
      </form>
    </div>
  );
}

export default EditNewProducts;
