import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";

function SubCategorys() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);

  console.log("data in sub category", data);

  useEffect(() => {
    // Fetch category data
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });

    // Fetch subcategory data
    axios
      .get(`${API_BASE_URL}/subcategory/admin/getsubcategory`)
      .then((response) => {
        setData(response.data.subcategory);
        // console.log(response.data.subcategory)
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const deleteCategory = (categoryId) => {
    const shouldDelete = Swal.fire({
      title: "Are you sure you want to delete this Sub Category?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Delete!", "", "success");
        if (shouldDelete) {
          axios
            .delete(
              `${API_BASE_URL}/subcategory/admin/deletesubcategory/${categoryId}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item._id !== categoryId)
              );
            })
            .catch((error) => {
              console.error("Error deleting category:", error);

              if (error.response) {
                // Log the response data and status code for more information
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
              }
            });
        }
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    // Make a PUT request to your API to update the category status
    axios
      .put(`${API_BASE_URL}/subcategory/admin/status/${categoryId}`, {
        isActive: !isActive, // Toggle the active status
      })
      .then(() => {
        // Update the local state to reflect the change
        setData((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        if (error.response) {
          // Log the response data and status code for more information
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Sub Categorys</h2>
        <Link to="/subproductcategory" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table">
        <thead>
          <tr>
            <th>Sub Categorys</th>
            <th>Categorys</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.categoryId?.name}</td>
                <td>
                  <Link to={`/subCategorysDetial/${item._id}`}>
                    <button className="mx-1">
                      <AiFillEye className="m-2 fs-6" />
                    </button>
                  </Link>
                  <button
                    className="mx-1"
                    onClick={() => deleteCategory(item._id)}
                  >
                    <AiFillDelete className="m-2 fs-6" />
                  </button>
                  <Link to={`/editSubCategory/${item._id}`}>
                    <button className="mx-1">
                      <FiEdit className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() =>
                      toggleCategoryStatus(item._id, item.isActive)
                    }
                  >
                    {item.isActive ? (
                      <BsToggleOn className="m-2 fs-6" />
                    ) : (
                      <BsToggleOff className="m-2 fs-6" />
                    )}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No subcategories found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SubCategorys;
