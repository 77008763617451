import React, { useContext, useEffect, useState } from "react";
import brandLogo from "../../../Assets/Images/brand-logo.svg";
import "../Sidebar/Sidebar.scss";
import DashBoardIteams from "../../SidebarItems/DashBoardIteams";
import ProductsItems from "../../SidebarItems/ProductsItems";
import CouponItems from "../../SidebarItems/CouponItems";
import ShippingItems from "../../SidebarItems/ShippingItems";
import MasterItems from "../../SidebarItems/MasterItems";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { CgProfile } from "react-icons/cg";
import userContext from "../../useContext/userContext";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";

const Sidebar = () => {
  const [product, setProduct] = useState(null);

  const [admin, setAdmin] = useState();

  const open = useContext(userContext);

  useEffect(() => {
    const adminId = localStorage.getItem("adminId");

    const headers = {
      Authorization: `Bearer ${adminId}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/admin/profile`, {
        headers,
      })
      .then((result) => {
        setAdmin(result.data.admin);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div
      className={
        open.closeSideBar ? "sidebar active overflow-hidden" : "sidebar"
      }
    >
      <Link to="/profilepage" className="text-decoration-none text-dark">
        <CgProfile className=" text-dark fs-1" />
        <span className="user-name mx-2">
          {admin ? admin.name : "Loading..."}
        </span>
      </Link>

      <ul className="sidebar-items">
        {/* DashBoard */}
        {/* <DashBoardIteams /> */}
        {/* DashBoard */}

        {/* Products */}
        <ProductsItems />
        {/* Products */}

        {/* Coupon */}
        {/* <CouponItems /> */}
        {/* Coupon */}

        {/* Shipping */}
        {/* <ShippingItems /> */}
        {/* Shipping */}

        {/* Master */}
        <MasterItems />
        {/* Master */}
      </ul>
    </div>
  );
};

export default Sidebar;
