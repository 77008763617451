import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import {API_BASE_URL} from "../../../utils/index"


function DescriptionMethod() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Make an HTTP GET request
    axios
      .get(`${API_BASE_URL}/formfieldcategory/admin/getformfieldcategories`)
      .then((response) => {
        // Update the state with the fetched data
        setData(response.data.formfieldcategory);
        // console.log('Data:', response.data.formfieldcategory);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Function to delete a category start
  const deleteCategory = (categoryId) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this Description Type?"
    );

    if (shouldDelete) {
      axios
        .delete(
          `${API_BASE_URL}/formfieldcategory/admin/deleteformfieldcategory/${categoryId}`
        )
        .then(() => {
          setData((prevData) =>
            prevData.filter((item) => item._id !== categoryId)
          );
        })
        .catch((error) => {
          console.error("Error deleting category:", error);

          if (error.response) {
            // Log the response data and status code for more information
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
          }
        });
    }
  };
  // // Function to delete a category end

  const toggleCategoryStatus = (categoryId, isActive) => {
    // Make a PUT request to your API to update the category status
    axios
      .put(`${API_BASE_URL}/formfieldcategory/admin/status/${categoryId}`, {
        isActive: !isActive, // Toggle the active status
      })
      .then(() => {
        // Update the local state to reflect the change
        setData((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        if (error.response) {
          // Log the response data and status code for more information
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Description Method</h2>
        <Link to="/createDescriptionMethod" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>
                <Link to={`/descriptionMethod/${item._id}`}>
                  <button className="mx-1">
                    <AiFillEye className="m-2 fs-6" />
                  </button>
                </Link>
                <button
                  className="mx-1"
                  onClick={() => deleteCategory(item._id)}
                >
                  <AiFillDelete className="m-2 fs-6" />
                </button>
                <Link to={`/editDescriptionMethod/${item._id}`}>
                  <button className="mx-1">
                    <FiEdit className="m-2 fs-6" />
                  </button>
                </Link>

                <button
                  className="mx-1"
                  onClick={() => toggleCategoryStatus(item._id, item.isActive)}
                >
                  {item.isActive ? (
                    <BsToggleOn className="m-2 fs-6" />
                  ) : (
                    <BsToggleOff className="m-2 fs-6" />
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DescriptionMethod;
