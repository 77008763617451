import React, { useState } from "react";
import "../Headers/Sidebar/Sidebar.scss";
import product_icon from "../../Assets/Images/product-icon.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

function ShippingItems() {
  const [menuOpenShipping, setMenuOpenShipping] = useState(false);

  return (
    <div className="text-decoration-none">
      <li
        onClick={() => setMenuOpenShipping(!menuOpenShipping)}
        aria-controls="example-collapse-text"
        aria-expanded={menuOpenShipping}
      >
        <div className="d-flex">
          <img className="sidebar-icons" src={product_icon} />
          <div className="sidebar-text">Shipping</div>
        </div>
        {menuOpenShipping === false ? (
          <IoIosArrowForward className="sidebar-menu-icon" />
        ) : (
          <IoIosArrowDown className="sidebar-menu-icon" />
        )}
      </li>

      <Collapse in={menuOpenShipping}>
        <div id="example-collapse-text">
          <Link
            className={
              "text-dark text-decoration-none"
            }
            style={{ fontSize: "13px" }}
            to="/"
          >
            <li className="mx-2">Shipping Class</li>
          </Link>
          <Link
            className={
              "text-dark text-decoration-none"
            }
            style={{ fontSize: "13px" }}
            to="/"
          >
            <li className="mx-2">Add New Shipping</li>
          </Link>
        </div>
      </Collapse>
    </div>
  );
}

export default ShippingItems;