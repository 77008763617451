import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/index";
import SuccessMessage from "../../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import CancelButton from "../../../../components/Buttons/CancelButton";

function EditCategory() {
  const { _id } = useParams();
  const [newCategoryName, setNewCategoryName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getsinglecategory/${_id}`)
      .then((response) => {
        setNewCategoryName(response.data.category.name);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const data = { name: newCategoryName };
    axios
      .put(`${API_BASE_URL}/category/admin/updatecategory/${_id}`, data)
      .then((response) => {
        console.log("response :", response);
        SuccessMessage("Category Update");
        navigate("/category");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Category</h2>
      <form onSubmit={handleSave} className="category">
        <div>
          <label htmlFor="name" className="fs-6">
            Change Category Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit">Save</button>
          <CancelButton path="/category" />
        </div>
      </form>
    </div>
  );
}

export default EditCategory;
