import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/index";
import CancelButton from "../../../../components/Buttons/CancelButton";
import SuccessMessage from "../../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";

function EditDescriptionType() {
  const { _id } = useParams();
  const [newEditDescriptionName, setNewEditDescriptionName] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singledescription/${_id}`)
      .then((response) => {
        setNewEditDescriptionName(response.data?.description?.descriptionName);
        setData(response.data?.description);
        // console.log("singledescription", response.data.description);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    axios
      .put(`${API_BASE_URL}/admin/updatedescription/${_id}`, {
        descriptionName: newEditDescriptionName,

        subcategoryname: data?.subcategory?.name,
        categoryname: data?.category?.name,
      })
      .then((response) => {
        SuccessMessage("Update Description");
        navigate("/descriptionType");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Description Type</h2>
      <form onSubmit={handleSave} className="category">
        <div>
          <label htmlFor="">Category : </label>{" "}
          <span> {data?.category?.name} </span>
        </div>

        <div>
          <label htmlFor="">Sub Category : </label>{" "}
          <span> {data?.subcategory?.name} </span>
        </div>

        <div>
          <label htmlFor="name" className="fs-6">
            Change Description Type Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={newEditDescriptionName}
            onChange={(e) => setNewEditDescriptionName(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit">Save</button>
          <CancelButton path="/descriptionType" />
          {/* <button>Cancel</button> */}
        </div>
      </form>
    </div>
  );
}

export default EditDescriptionType;
