import React from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

function ErrorMessage(data) {
  console.log("error data ErrorMessage:", data);

  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: data.response.data.message,
  });
}

export default ErrorMessage;
