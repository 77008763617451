import React, { useEffect, useState } from "react";
import "../ProductCategory/productCategory.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DescriptionTypeSubCategorys from "../../../components/DescriptionType/DescriptionTypeSubCategorys";
import DescriptionTypeCategorys from "../../../components/DescriptionType/DescriptionTypeCategorys";
import { API_BASE_URL } from "../../../utils/index";
import { useAuthHeaders } from "../../../components/Token";
import CancelButton from "../../../components/Buttons/CancelButton";
import Swal from "sweetalert2/dist/sweetalert2.js";

function CreateDescriptionType() {
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [descriptionName, setDescriptionName] = useState();
  const navigate = useNavigate();

  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        // Update the state with the fetched subcategory data
        setCategory(response.data.categories);
        // console.log(response.data.categories, "getcategory")
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const handleCategoryCreation = (e) => {
    e.preventDefault();

    if (selectedCategory && selectedSubCategory && descriptionName) {
      axios
        .post(
          `${API_BASE_URL}/admin/descriptionname`,
          {
            descriptionName: descriptionName,
            subcategoryname: selectedSubCategory,
            categoryname: selectedCategory,
          },
          { headers }
        )
        .then((result) => {
          console.log(result);
          navigate("/descriptionType");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Swal.fire({
        // title: "The Internet?",
        text: "Please Select All Fields",
        icon: "question",
      });
    }
  };

  return (
    <div className="p-3">
      <h2>Create Description Name</h2>
      <form onSubmit={handleCategoryCreation} className="category">
        <div className="row category-field">
          <DescriptionTypeCategorys
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            category={category}
            setSubCategory={setSubCategory}
          />

          <DescriptionTypeSubCategorys
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            subCategory={subCategory}
          />

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Description Name:
            </label>
            <br />
            <input
              type="text"
              id="descriptionName"
              onChange={(e) => setDescriptionName(e.target.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <button>Save</button>
          <CancelButton path="/descriptionType" />
        </div>
      </form>
    </div>
  );
}

export default CreateDescriptionType;
