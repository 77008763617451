import React, { useState } from "react";
import Select from "react-select";

function SelectDescriptionType(props) {
  const [selectedData, setSelectedData] = useState([]);

  console.log(selectedData);

  const handleChange = (selectedOption) => {
    // Ensure that you have at least two selected options before setting the data
    if (selectedOption.length >= 0) {
      // Extract the labels from the selected options and join them into a single string
      const selectedLabels = selectedOption.map((option) => option.label);

      // Set selectedLabels using setSelectedData
      setSelectedData(selectedLabels);

      // You can use selectedLabels as needed
      console.log("Labels of the selected options:", selectedLabels);

      // Pass selectedOption to props.onChange if needed
      props.onChange(selectedLabels);
    }
  };
  
  if (!Array.isArray(props.discriptionType)) {
    console.error("props.discriptionType is not an array:", props.discriptionType);
    return null; // or handle the error appropriately
  }

  const flattenedOptions = props.discriptionType.flatMap((option) =>
    option.descriptiontypes.map((descriptiontype) => ({
      value: descriptiontype, 
      label: descriptiontype,
    }))
  );

  return (
    <div className="App">
      <Select 
        isMulti={true}
        value={props.Id}
        onChange={handleChange}
        options={flattenedOptions}
      />
    </div>
  );
}

export default SelectDescriptionType;
