import React, { useEffect, useState } from "react";
import "../../Pages/DashBoard/DashBoard.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Counter from "../../components/Counter/Counter";
import axios from "axios";
import { API_BASE_URL } from "../../utils";

function DashBoard() {
  const [allproducts, setAllproducts] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/products/admingetallproducts`)
      .then((res) => {
        // console.log("data in ", res.data);
        setAllproducts(res.data.products.length);
      })
      .catch((error) => {
        console.log("error", error);
      });

    axios
      .get(`${API_BASE_URL}/admin/order/getOrders`)
      .then((res) => {
        console.log("data in ", res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const cardData = [
    {
      className: "",
      count: data.pendingCount,
      title: "Pending",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
    {
      className: "bg-success",
      count: 1,
      title: "Order Confirm",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
    {
      className: "bg-warning",
      count: 1,
      title: "Order Cancel",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
    {
      className: "bg-danger",
      count: data.totalOrders,
      title: "Total Order",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
    {
      className: "bg-danger",
      count: data.cancelOrder,
      title: "Cancel Order",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
    {
      className: "",
      count: allproducts,
      title: "Total Products",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
    {
      className: "",
      count: data.totalOrderAmount,
      title: "Total Amount",
      color: "",
      buttonText: "View More",
      icon: "bi bi-arrow-right-circle",
    },
  ];

  return (
    <div className="dashboard container">
      <h1 className="text-dark py-2">DashBoard </h1>
      <div className="card-container">
        {cardData.map((card, index) => (
          <div key={index} className={`card ${card.className}`}>
            <div className="card-content">
              <h4>{card.count}</h4>
              <p>{card.title}</p>
            </div>
            <div className="btn card-botton">
              {card.buttonText}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className={card.icon}
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>

      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" className="text-center" title="Week">
          <h1>Weekley Sales Charts</h1>
        </Tab>
        <Tab eventKey="profile" className="text-center" title="Month">
          <h1>Monthly Sales Charts</h1>
        </Tab>
        <Tab eventKey="contact" className="text-center" title="Year">
          <h1>Yearly Sales Charts</h1>
        </Tab>
      </Tabs>
    </div>
  );
}

export default DashBoard;