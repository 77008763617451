import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {API_BASE_URL} from "../../../../utils/index"


function SubsubCategorysDetial() {
  const { _id } = useParams();

  const [subCategory, setSubCategory] = useState({});

  console.log(subCategory, "subCategory detial");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/subcategory/admin/singlesubcategory/${_id}`)
      .then((response) => {
        setSubCategory(response.data.subcategory);
        // console.log(response.data.subcategory,"response")
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>subCategory Details</h2>
      <h6>Name:</h6>
      <p>{subCategory.name}</p>
      <h6>createdAt</h6>
      <p>{subCategory.createdAt}</p>
    </div>
  );
}

export default SubsubCategorysDetial;
