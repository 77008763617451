import React, { useEffect, useState } from "react";
import "../ProductCategory/productCategory.scss";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import SuccessMessage from "../../../components/Messages/SuccessMessage";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import { useAuthHeaders } from "../../../components/Token";
import CancelButton from "../../../components/Buttons/CancelButton";

function SubProductCategory() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const headers = useAuthHeaders();

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/subcategory/admin/newsubcategory`,
        {
          name: subCategoryName,
          categoryId: selectedCategory,
        },
        { headers }
      )
      .then((response) => {
        setIsSuccess(true);
        SuccessMessage("Create Sub Category");
        navigate("/subCategory");
        setSubCategoryName("");
        setSelectedCategory("");
      })
      .catch((error) => {
        console.error("Error adding sub-category:", error);
        setIsSuccess(false);
        ErrorMessage(error);
      });
  };

  return (
    <div className="p-4">
      <h2>Sub Category</h2>
      <form onSubmit={handleFormSubmit} className="category">
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategory}
              required
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Sub Category Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={subCategoryName}
              onChange={(e) => setSubCategoryName(e.target.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <button>Save</button>
          <CancelButton path="/subCategory" />
        </div>
      </form>
      {isSuccess && (
        <p className="text-danger">Sub category added successfully!</p>
      )}
    </div>
  );
}

export default SubProductCategory;
